import { Box } from "@mui/material";
import React from "react";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { red } from "@mui/material/colors";

const CRUDCategoryComponent = ({ id, name, handleEdit, handleDelete }) => {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        alignContent: "center",
        justifyContent: "space-evenly",
      }}
    >
      <IconButton
        aria-label="update"
        color="primary"
        onClick={() => handleEdit({ _id: id, name })}
      >
        <EditIcon color="primary" />
      </IconButton>
      
      {/* Add handleDelete functionality here */}
      <IconButton
        aria-label="delete"
        onClick={() => handleDelete(id)}  // Trigger delete on click
      >
        <DeleteIcon sx={{ color: red[400] }} />
      </IconButton>
    </Box>
  );
};

export default CRUDCategoryComponent;