import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { getCategories, newCategory, updateCategory,deleteCategory } from "../services/servicesServices";
import { getCategoriesProduct, newCategoryProduct, updateCategoryProduct,deleteCategoryProduct } from "../services/servicesProducts";
import Principal from "./Principal";
import { DataGrid } from "@mui/x-data-grid";
import { grey } from "@mui/material/colors";
import CRUDCategoryComponent from "../components/CRUDCategoryComponent";

const CategoryPage = () => {
  const [categories, setCategories] = useState([]);
  const [result, setResult] = useState('');  
  const [created, setCreated] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(false);  
  const [openModal, setOpenModal] = useState(false);  
  const [editMode, setEditMode] = useState(false);  
  const [editCategoryId, setEditCategoryId] = useState(null);  
  const navigate = useNavigate();
  const { id } = useParams();

  const formik = useFormik({
    initialValues: {
      name: "",
      businessId: localStorage.getItem("Business"),
      createdAt: "",  // Add CreatedAt to the initial values
    },
    validationSchema: Yup.object({
      name: Yup.string().required("La categoría necesita un nombre"),
    }),
    onSubmit: async (values) => {
      console.log(editCategoryId,'\n',values)
      setLoading(true);
      let resp;
      
      if (editMode) {  // Handle update
        if (localStorage.getItem("type") === "services") {
          resp = await updateCategory(editCategoryId, values);
        } else {
          resp = await updateCategoryProduct(editCategoryId, values);
        }
        if (resp.name) {
          setResult(`La categoría ${resp.name} fue actualizada correctamente.`);
          setUpdated(true);  
        } else {
          setResult(`Error al actualizar la categoría: ${resp.code}`);
        }
      } else {  // Handle create
        if (localStorage.getItem("type") === "services") {
          resp = await newCategory(values);
        } else {
          resp = await newCategoryProduct(values);
        }
        if (resp.success) {
          setResult(`La categoría ${resp.data.name} fue creada correctamente.`);
          setCreated(true);  
        } else {
          setResult(`Error al crear la categoría: ${resp.code}`);
        }
      }
      
      setLoading(false);
      setOpenModal(true);  
      setEditMode(false);  
      formik.resetForm();  
    },
  });

  const handleDelete = async (categoryId) => {
    setLoading(true);
    let resp;
    
    if (localStorage.getItem("type") === "services") {
      resp = await deleteCategory(categoryId); // Call your delete service
    } else {
      resp = await deleteCategoryProduct(categoryId); // Call your delete service for products
    }
    
    if (resp.success) {
      setResult(`La categoría fue eliminada correctamente.`);
      setDeleted(true);  // Trigger re-fetching
    } else {
      setResult(`Error al eliminar la categoría: ${resp.code}`);
    }
    
    setLoading(false);
    setOpenModal(true);  // Open modal to show result
  };

  const handleEdit = (category) => {
    formik.setFieldValue("name", category.name);
    formik.setFieldValue("createdAt", category.createdAt);
    setEditCategoryId(category._id);
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    formik.resetForm();
    setEditMode(false);
    setEditCategoryId(null);
  };

  const handleCloseModal = () => {
    setOpenModal(false);  
    setResult('');
  };

  const columns = [
    { field: "name", headerName: "NOMBRE", minWidth: "180" },
    {
      field: "operations",
      headerName: "ACCIONES",
      renderCell: (params) => (
        <CRUDCategoryComponent
          id={params.row._id}
          name={params.row.name}
          handleEdit={() => handleEdit(params.row)}
          handleDelete={handleDelete}
        />
      ),
    },
  ];

  useEffect(() => {
    const getBusinessCategories = async () => {
      let resp;
      if (localStorage.getItem("type") === "services") {
        resp = await getCategories(id);
      } else {
        resp = await getCategoriesProduct(id);
      }

      if (!resp.error) {
        setCategories(resp);
      } else if (resp.status === 403) {
        navigate("/login");
      }
    };
    getBusinessCategories();
    setCreated(false);  
  }, [id, created, updated, deleted]);

  return (
    <Principal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          backgroundColor: "#fff",
          paddingTop: "90px",
          paddingBottom: "24px",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
            borderBottom: `1px solid ${grey[300]}`,
          }}
        >
          <Typography variant="h6" sx={{ marginRight: "16px" }}>
            Lista de Categorías
          </Typography>
          <form onSubmit={formik.handleSubmit} style={{ display: 'flex', alignItems: 'center', gap: '16px' }}>
            <FormControl sx={{ width: "200px" }}>
              <TextField
                id="name"
                name="name"
                label="Nombre de Categoría"
                variant="filled"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={loading}  
              />
              {formik.touched.name && formik.errors.name && (
                <FormHelperText error>{formik.errors.name}</FormHelperText>
              )}
            </FormControl>
            <Button variant="contained" type="submit" disabled={loading}>
              {loading ? "Guardando..." : editMode ? "Actualizar" : "Crear"}
            </Button>
            <Button variant="outlined" onClick={handleCancelEdit} disabled={loading}>
              {editMode ? "Cancelar" : "Cancelar"}
            </Button>
          </form>
        </Box>

        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "16px",
          }}
        >
         <DataGrid
          sx={{
            mt: 2,
            width: "75vw",
            borderColor: "primary.light",
            backgroundColor: grey[100],
            '.MuiDataGrid-iconButtonContainer': {
              visibility: 'visible',
            },
            '.MuiDataGrid-sortIcon': {
              color: "white",
              opacity: 'inherit !important',
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#2818A0",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "white",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            "& .MuiDataGrid-columnHeader.Mui-active": {
              color: '#40B6E9', // Active header color
            },
            "& .MuiDataGrid-columnHeader.Mui-active .MuiDataGrid-sortIcon": {
              color: '#40B6E9', // Active sort icon color
            },
          }}
          getRowId={(row) => row._id}
          rows={categories}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
        </Box>
      </Box>

      <Dialog open={openModal} onClose={handleCloseModal}>
  <DialogContent
    sx={{
      backgroundColor: "#003366",
      color: "#ffffff",
      textAlign: "center",
    }}
  >
    <Typography variant="h6">
      {result}
    </Typography>
  </DialogContent>
  <DialogActions
    sx={{
      backgroundColor: "#003366",
      paddingBottom: "16px",
    }}
  >
    <Button
      onClick={handleCloseModal}
      variant="contained"
      sx={{
        backgroundColor: "#add8e6",
        color: "#003366",
        "&:hover": {
          backgroundColor: "#87cefa",
        },
      }}
    >
      Continuar
    </Button>
  </DialogActions>
</Dialog>


    </Principal>
  );
};

export default CategoryPage;
