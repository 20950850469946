import { Box, Button, FormControl, FormHelperText, TextField, Typography, Paper } from '@mui/material';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { newCategory } from '../services/servicesServices';
import { newCategoryProduct } from '../services/servicesProducts';

const NewCategoryModal = ({ created }) => {
    const [result, setResult] = useState('');
    const [loading, setLoading] = useState(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            businessId: localStorage.getItem('Business'),
        },
        validationSchema: Yup.object({
            name: Yup.string().required('La categoría necesita un nombre'),
        }),
        onSubmit: async (values) => {
            setLoading(true);
            let resp;
            if (localStorage.getItem('type') === 'services') {
                resp = await newCategory(values);
            } else {
                resp = await newCategoryProduct(values);
            }
            if (resp.success) {
                setLoading(false);
                setResult(`La categoría ${resp.data.name} fue creada correctamente.`);
            } else {
                setLoading(false);
                setResult(`Ha ocurrido un error de tipo ${resp.code}`);
            }
            created();
        },
    });

    const onClose = () => {
        setResult('');
        formik.resetForm();
    };

    return (
        <Paper
            elevation={3}
            sx={{
                padding: '24px',
                width: '100%',
                maxWidth: '500px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography variant="h5" color="primary">
                Nueva Categoría
            </Typography>
            {result === '' ? (
                <form onSubmit={formik.handleSubmit}>
                    <FormControl sx={{ width: '100%' }}>
                        <TextField
                            id="name"
                            name="name"
                            label="Nombre"
                            variant="filled"
                            fullWidth
                            InputLabelProps={{ shrink: true }}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <FormHelperText error>{formik.errors.name}</FormHelperText>
                        )}
                    </FormControl>
                    <Box sx={{ display: 'flex', gap: '16px', justifyContent: 'center', marginTop: '16px' }}>
                        <Button variant="contained" type="submit" disabled={loading}>
                            {loading ? 'Creando...' : 'Crear'}
                        </Button>
                        <Button variant="outlined" onClick={onClose}>
                            Cancelar
                        </Button>
                    </Box>
                </form>
            ) : (
                <Typography variant="h6" sx={{ textAlign: 'center', mt: 2 }}>
                    {result}
                </Typography>
            )}
            {result !== '' && (
                <Button variant="contained" onClick={onClose} sx={{ marginTop: '16px' }}>
                    Continuar
                </Button>
            )}
        </Paper>
    );
};

export default NewCategoryModal;
