import { Box, Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DataGrid } from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Principal from "./Principal";
import { getServices } from "../services/servicesServices";
import CRUDServiceComponent from "../components/CRUDServiceComponent";
import { getProducts } from "../services/servicesProducts";

const ProductService = () => {
  const [selectedRow, setSelectedRow] = useState([]);
  const [deleted, setDeleted] = useState(false);
  const [services, setServices] = useState([]);
  const { id } = useParams();
  const type = localStorage.getItem("type");
  const navigate = useNavigate();

  const newProductService = () => {
    navigate(type === "services" ? "/newService" : "/newProduct");
  };

  const columns = [
    { field: "name", width: 200, headerName: "Nombre" },
    { field: "description", width: 300, headerName: "Descripción" },
    { field: "code", width: 150, headerName: "Código" },
    {
      field: "category",
      width: 200,
      headerName: "Categoría",
      valueGetter: (params) => params.row.categoryId.name,
    },
    {
      field: "price",
      headerName: "Precio",
      valueGetter: (params) => `${params.row.price} ${params.row.coin}`,
    },
    {
      field: "operations",
      headerName: "Acciones",
      renderCell: (params) => (
        <CRUDServiceComponent
          id={params.row._id}
          deleted={() => setDeleted(true)}
        />
      ),
    },
    {
      field: "addButton",
      headerName: "",
      flex: 1, // Ensures this column takes the remaining space
      minWidth: 80, // Sets a minimum width
      sortable: false, // Disables sorting for this column
      disableColumnMenu: true, // Hides the column menu
      renderHeader: () => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end", // Aligns the button to the far right
            alignItems: "center", // Centers the button vertically
            width: "100%", // Ensures the box spans the entire column width
            paddingRight: "8px", // Optional: Adds space from the grid edge
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={newProductService}
            sx={{
              minWidth: "40px",
              height: "40px",
              borderRadius: "50%",
              padding: 0,
            }}
          >
            <AddIcon />
          </Button>
        </Box>
      ),
      renderCell: () => null, // No content for individual cells in this column
    }
    
  ];
  

  useEffect(() => {
    const fetchData = async () => {
      const response =
        type === "services" ? await getServices(id) : await getProducts(id);
      if (!response.error) {
        setServices(response);
      } else if (response.status === 403) {
        navigate("/login");
      }
    };
    fetchData();
  }, [id, deleted]);

  return (
    <Principal>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20px",
          backgroundColor: "#fff",
          height: "100%",
          padding: "90px 0 24px",
        }}
      >
        <DataGrid
          sx={{
            width: "75vw",
            backgroundColor: 'white',
            borderRadius:'15px',
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            "& .MuiDataGrid-row": {
              padding: "0 15px",
            },
            "& .MuiDataGrid-cell": {
              padding: "10px 20px",
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: "#2818A0",
              borderTopLeftRadius: "15px",
              borderTopRightRadius: "15px",
              justifyContent: "space-between",
            },
            "& .MuiDataGrid-columnHeader:first-child": {
              justifyContent: "flex-end", 
              display: "flex",
              paddingLeft: "36px"
            },
            "& .MuiDataGrid-columnHeader:last-child": {
              justifyContent: "flex-end",
              display: "flex",
              paddingRight: "30px",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              color: "white",
              fontWeight: "bold",
            },
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
          }}
          localeText={{
            toolbarDensity: "Tamaño",
          }}
          rows={services}
          getRowId={(row) => row._id}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
        />
      </Box>
    </Principal>
  );
};

export default ProductService;
